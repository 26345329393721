import React from 'react';
import { useLocation } from 'react-router-dom';
import SetPassword from './SetPassword';

const SetPasswordIndex = () => {
  const location = useLocation();
  return <SetPassword location={location} />;
};

export default SetPasswordIndex;
